import { useQueryClient } from "@tanstack/react-query"

import {
  TDecisionsFilter,
  TUserDecisionsPostResponse,
  TUserDecisionsResponse,
} from "src/data/userDecisions/userDecisionsTypes"

export const userDecisionsKeys = {
  /** Use to target all user decisions in the cache */
  all: () => {
    return ["userDecisions"] as const
  },

  /** Use to target a specific users decisions */
  decisions: ({
    userId,
    filters,
  }: {
    userId: string
    filters?: TDecisionsFilter
  }) => {
    if (filters) {
      return [...userDecisionsKeys.all(), userId, filters] as const
    }

    return [...userDecisionsKeys.all(), userId] as const
  },
}

export function useUserDecisionsQueryCache() {
  const queryClient = useQueryClient()

  function addCachedDecision({
    userId,
    decision,
  }: {
    userId: string
    decision: TUserDecisionsPostResponse
  }) {
    queryClient.setQueriesData<TUserDecisionsResponse | undefined>(
      userDecisionsKeys.decisions({ userId }),
      (cache) => {
        if (!cache) return

        const cachedDecisions = cache.decisions ?? []
        const newCache = {
          ...cache,
          decisions: [decision, ...cachedDecisions],
        }
        return newCache
      }
    )
  }

  return {
    addCachedDecision,
  }
}
