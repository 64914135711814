export const callAssistKeys = {
  /** Use to target *all* call assist data in the cache, with fuzzy matching; matching this
   * way is ineffective and should be used with care */
  all() {
    return ["call_assist"] as const
  },

  /** Use to target event data in cache*/
  event(id: string) {
    return [...this.all(), "event", id] as const
  },

  state(profileId: string) {
    const level = 3
    return [...this.all(), profileId, level, "active"] as const
  },

  estimate() {
    return [...this.all(), "estimate"] as const
  },

  subscription(userId: string) {
    return [...this.all(), "subscription", userId] as const
  },

  guests(orgId: string, filter?: { limit?: number; offset?: number }) {
    if (filter) {
      return [...this.all(), "guests", orgId, filter] as const
    }
    return [...this.all(), "guests", orgId] as const
  },
}
